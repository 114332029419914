//Primary
export const primaryBlue = '#002448';
export const primaryTeal = '#7AD8D4';

//Neutral
export const white001 = '#FFFFFF';
export const grey001 = '#F5F5F7';
export const grey002 = '#929292';
export const grey003 = '#252525';

//Base
export const basePink001 = '#FAD4E4';
export const baseOrange001 = '#FFCC78';
export const baseYellow001 = '#FFE4AF';
export const basePurple001 = '#DFD0F5';
export const baseTeal001 = '#BEEDED';
export const baseGreen001 = '#C8F2D6';
export const baseGray001 = '#808285';

//Base/hover
export const hoverBasePink002 = '#F5B0CC';
export const hoverBaseOrange002 = '#FF9B6C';
export const hoverBaseYellow002 = '#FFC7A6';
export const hoverBasePurple002 = '#C3AAEB';
export const hoverBaseTeal002 = '#8DDCDC';
export const hoverBaseGreen002 = '#9DE6B4';
export const hoverPrimary001 = '#4C657F';
export const hoverOutlineDark = 'rgba(0, 36, 72, 0.1)';
export const hoverOutlineLight = 'rgba(255, 255, 255, 0.2)';
export const hoverTextLight = 'rgba(255, 255 ,255 ,0.7)';
export const hoverBrightBlue = '#00ceed';
export const hoverBrightOrange = '#edab00';
export const hoverBrightYellow = '#dfed3b';
export const hoverBrightGreen = '#4ced00';

//Button
export const pressedDark = '#8B9EB2';
export const pressedLight = '#DCDCDC';
export const pressedOutlineDark = 'rgba(146, 146, 146, 0.5)';
export const pressedOutlineLight = 'rgba(255, 255, 255, 0.5)';

//Level
export const level1Green = '#2d7500';
export const level2Purple = '#8930B4';
export const level2Red = '#DE3700';
export const level3Pink = '#E40072';

//Tag
export const tagBlue001 = '#002A68';
export const tagRed001 = '#6A0040';
export const tagGreen001 = '#004039';
export const tagPurple001 = '#5A0068';

//Tag/hover
export const hoverTagBlue002 = '#002357';
export const hoverTagRed002 = '#5C0037';
export const hoverTagGreen002 = '#00332D';
export const hoverTagPurple002 = '#4B0057';

//Bright
export const brightBlue = '#00DDFF';
export const brightOrange = '#FFB800';
export const brightYellow = '#EEFF41';
export const brightGreen = '#51FF00';

//Extra

export const red001 = '#D63900';
export const red002 = '#FD3C35';
export const blue001 = '#1A63EF';
export const liveRed = '#FF0000';

// Card
export const orangeStar = '#FBC230';

// DatePicker Calendar WeekDay Label
export const weekDayGrey = '#AAAAAA';

// Secondary
export const secondaryYellow = '#FFD731';
export const secondaryGreen = '#00AF66';

// Quest Tags
export const tagEasy = secondaryGreen;
export const tagModerate = level2Purple;
export const tagChallenging = level2Red;
