type FormatOptions = {
  locale?: string;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
};
/**
 * Formats a number into a human-readable string with appropriate suffixes
 *
 * Formatting rules:
 * - Below 10,000: Comma separated (e.g., "9,999")
 * - 10k to 999.9k: k suffix with one decimal (e.g., "10.5k", "999.9k")
 * - 1M to 999.9M: M suffix with one decimal (e.g., "1.5M", "999.9M")
 * - 1B and above: B suffix with one decimal (e.g., "1.5B", "10B")
 */
const THRESHOLDS = {
  THOUSAND: 10_000,
  MILLION: 1_000_000,
  BILLION: 1_000_000_000,
};

export const numberFormatter = (options: FormatOptions = {}) => {
  const { locale = "en", maximumFractionDigits = 1, minimumFractionDigits = 0 } = options;

  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits,
    maximumFractionDigits,
  });

  return formatter;
};

export function formatPoints(amount: number, options: FormatOptions = {}): string {
  const formatter = numberFormatter(options);

  if (amount < THRESHOLDS.THOUSAND) {
    return formatter.format(amount);
  }

  if (amount >= THRESHOLDS.BILLION) {
    const value = Math.floor((amount / THRESHOLDS.BILLION) * 10) / 10;
    return `${formatter.format(value)}B`;
  }

  if (amount >= THRESHOLDS.MILLION) {
    const value = Math.floor((amount / THRESHOLDS.MILLION) * 10) / 10;
    return `${formatter.format(value)}M`;
  }

  const value = Math.floor((amount / 1000) * 10) / 10;
  return `${formatter.format(value)}k`;
}
