import { Connection, Steps } from "ge_api";
import { useGeApi } from "./useGeApi";
import { useGeApiResults } from ".";
import geApi from "@utils/geApi";

type I = Connection["service_ident"];
export const useGeDataConnections = () => {
  const api = useGeApiResults<Connection>("data/connections", {});
  const { findConnection, byIdent, postServiceReturn } = useMethods();

  return {
    ...api,
    findConnection,
    postServiceReturn,
    apple: findConnection("apple"),
    google: findConnection("google"),
    fitbit: findConnection("fitbit"),
    garmin: findConnection("garmin"),
  };

  function useMethods() {
    return {
      findConnection: (ident: I) => {
        const connection = api.data.find(byIdent(ident));
        if (!connection) {
          console.error(`Connection with ident ${ident} not found`);
        }
        return (connection as unknown) as Connection;
      },
      byIdent: (ident: I) => (c: Connection) => ident === c.service_ident,
      postServiceReturn: async (service_ident: string, return_to: string) => {
        const api = geApi(`data/connections`);
        return await api.post({
          service_ident,
          return_to,
        });
      },
    };
  }
};

export const useGeSteps = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const api = useGeApi<Steps>(
    "steps/0",
    {
      days: 365,
      tz: encodeURIComponent(timezone),
    },
    "get",
    false
  );

  return {
    ...api,
    steps: api.data,
    fetch: api.refetch,
    error: api.error,
  };
};

export const useGeManualSteps = () => {
  const api = useGeApi<Steps>("steps/manual/0", {}, "get", false);

  return {
    ...api,
    steps: api.data,
    fetch: api.refetch,
    error: api.error,
  };
};
