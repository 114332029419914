import { ApiResponse, ResultsResponse } from "ge_api";
import { useInfiniteQuery, UseInfiniteQueryResult, useQuery } from "@tanstack/react-query";
import geApi from "@utils/geApi";
import { useState } from "react";

/**
 * A wrapper hook for React Query for making API requests to the Ge API.
 *
 * @template T - The type of data expected from the API.
 * @param {string} endpoint - The API endpoint to request data from.
 * @param {object} [params] - Optional parameters to pass to the API request.
 * @param {string} [method] - Optional HTTP method to use for the request. Defaults to "get".
 * @param {boolean} [usePrefix] - Optional flag indicating whether to prefix the API endpoint with "/ge". Defaults to true.
 * @returns {ApiResponse<T>} - An object containing the API response data and other metadata.
 */
export function useGeApi<T>(
  endpoint: string,
  params?: object,
  method: string = "get",
  usePrefix: boolean = true
): ApiResponse<T> {
  return useQuery({
    queryKey: [{ endpoint, method, params }],
    async queryFn() {
      const api = geApi(endpoint, usePrefix);
      const response = await api[method](params);
      return response.data as T;
    },
    refetchOnWindowFocus: true,
    staleTime: 5000,
  });
}

/**
 * A React Query hook for making a single API request to the Ge API.
 *
 * @template T - The type of data expected from the API.
 * @param {string} endpoint - The API endpoint to request data from.
 * @param {object} [params] - Optional parameters to pass to the API request.
 * @returns {object} - An object containing the API response data, pagination information, and other metadata.
 */

export function useGeApiResults<T>(endpoint: string, params?: object) {
  const api = useGeApi<ResultsResponse<T>>(endpoint, params);

  const paginationSchema = {
    page: 0,
    per_page: 0,
    results: [],
    total: 0,
    total_pages: 0,
  };

  const { page, per_page, results = [], total, total_pages } = api.data || paginationSchema;

  return {
    ...api,
    data: results,
    page,
    per_page,
    total,
    total_pages,
  };
}

/**
 * A wrapper hook for React Query for making infinite API requests to the Ge API.
 *
 * @template T - The type of data expected from the API.
 * @param {string} endpoint - The API endpoint to request data from.
 * @param {object} [params] - Optional parameters to pass to the API request.
 * @param {boolean} [usePrefix] - Optional flag indicating whether to prefix the API endpoint with "/ge". Defaults to true.
 * @returns {UseInfiniteQueryResult<ResultsResponse<T>>} - An object containing the API response data and other metadata.
 */
export function useGeApiInfinite<T>(
  endpoint: string,
  params?: object,
  usePrefix = true
): UseInfiniteQueryResult<ResultsResponse<T>, unknown> {
  return useInfiniteQuery({
    queryKey: [endpoint],
    async queryFn({ pageParam }): Promise<ResultsResponse<T>> {
      const api = geApi(endpoint, usePrefix);
      const response = await api.get({
        ...params,
        page: pageParam || 1,
      });

      return response.data as ResultsResponse<T>;
    },
    getNextPageParam: ({ total_pages, page }) => (page < total_pages ? page + 1 : undefined),
  });
}

/**
 * A custom hook for fetching paginated data from a given endpoint using infinite scrolling.
 *
 * @template T - The type of data expected from the endpoint.
 * @param {string} endpoint - The API endpoint to fetch data from.
 * @param {object} [initialParams] - Optional initial parameters for the API request.
 * @returns {object} - An object containing the accumulated data and additional properties
 *                     from the infinite query such as fetch status and pagination info.
 */
export function useGeApiInfiniteFlat<T>(endpoint: string, initialParams?: object) {
  const [params] = useState(initialParams);
  const api = useGeApiInfinite<T>(endpoint, params);
  const data = (api.data?.pages.flatMap((page) => page.results) || []) as T[];

  return {
    ...api,
    data,
  };
}
