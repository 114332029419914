import { ROUTES } from "../constants";

import renderAuthorizedRoute from "../AuthorizedRoute";
import ErrorBoundary from "../ErrorBoundary";

const AccessDenied = () => import("../../Auth/AccessDenied");

// Challenges
const Challenge = () => import("../../Challenges/Challenge");
const Challenges = () => import("../../Challenges");
const CreateChallenge = () => import("../../Challenges/CreateChallenge");
const InviteChallengers = () => import("../../Challenges/InviteChallengers");

// Team Challenges
const TeamChallenges = () => import("../../TeamChallenges");
const CreateChallengeTeams = () => import("../../TeamChallenges/CreateChallengeTeams");
const CreateTeamChallengeDetails = () => import("../../TeamChallenges/CreateTeamChallengeDetails");
const ManageChallengeTeams = () => import("../../TeamChallenges/ManageChallengeTeams");
const TeamChallengeDetails = () => import("../../TeamChallenges/TeamChallengeDetails");

// Champion
const Champion = () => import("../../Champion");

// Communities
const Communities = () => import("../../Communities");
const Community = () => import("../../Communities/Community");

// Courses
const Courses = () => import("../../Courses/Courses");
const PrivateCourses = () => import("../../Courses/PrivateCourses");
const CoursesCategories = () => import("../../Courses/Categories");
const Course = () => import("../../Courses/Course");
const LeaveCourseReview = () => import("../../Courses/LeaveCourseReview");
const ScheduleCourse = () => import("../../Courses/ScheduleCourse");
const RecommendedCourses = () => import("../../Courses/Recommended");
const SetupGroupVodCourse = () => import("../../Courses/SetupGroupVodCourse");
const CreateLiveRoom = () => import("../../Courses/CreateLiveRoom");
const CourseVideo = () =>
  import("../../Courses/CourseVideo").then((module) => ({
    default: module.CourseVideo,
  }));
const CourseLive = () =>
  import("../../Courses/CourseVideo").then((module) => ({
    default: module.CourseLive,
  }));
const LiveRecordings = () => import("../../Courses/LiveRecordings");
const PostAssessmentForm = () => import("../../Courses/Assessment/PostAssessmentForm");

// Design Guide
const DesignGuide = () => import("../../DesignGuide");
const Showcase = () => import("../../DesignGuide/Showcase");

// Home
const Home = () => import("../../Home");

// Partner Registration
const PartnerRegistration = () => import("../../Auth/PartnerRegistration");

// Instructors
const Instructors = () => import("../../Instructors");
const Instructor = () => import("../../Instructors/Instructor");

// Interests
const Interests = () => import("../../Interests");

// Partners
const Partners = () => import("../../Partners");
const Partner = () => import("../../Partners/Partner");

// Playlists
const MyPlaylist = () => import("../../Playlist");
const Playlist = () => import("../../Playlist/Playlist");
const AddPlaylistForm = () => import("../../Playlist/AddPlaylistForm");
const EditPlaylistCourses = () => import("../../Playlist/EditPlaylistCourses");
const BrowsePlaylists = () => import("../../Playlist/BrowsePlaylists");
const InviteUserRole = () => import("../../Playlist/InviteUserRole");

// Profile (User)
const Favorites = () => import("../../Favorites");
const Profile = () => import("../../Profile");
const EditProfile = () => import("../../Profile/EditProfile");
const EditDynamicFields = () => import("../../Profile/EditProfile/EditDynamicFields");
const ProfileMembershipPlan = () => import("../../Profile/PlanSubscription/ProfileMembershipPlan");
const MembershipConfirmationPage = () =>
  import("../../PlnNetwork/PlnMembership/MembershipConfirmationPage");

// Homework
const HomeworkList = () => import("../../Profile/Homeworks/HomeworkList");

const PlnLanding = () => import("../../PlnNetwork/PlnLanding/PlnLanding");

const WellnessLevel = () => import("../../Profile/EditProfile/WellnessLevel");
const WellnessHabits = () => import("../../WellnessHabits");
const WellnessGoals = () => import("../../WellnessGoals");
const Conditions = () => import("../../Conditions");
const Contacts = () => import("../../Profile/Contacts");
const Settings = () => import("../../Profile/Settings");
const Schedule = () => import("../../Profile/Schedule");
const Calendar = () => import("../../Profile/Calendar");
const History = () => import("../../Profile/History");
const SubAccounts = () => import("../../Profile/SubAccounts");
const MyContacts = () => import("../../Profile/Contacts/MyContacts");
const AddContact = () => import("../../Profile/Contacts/AddContact");

// Onboarding
const SubscriptionNew = () => import("../../SubscriptionNew");
const SubscriptionUpgrade = () => import("../../SubscriptionUpgrade");
const SubscriptionDowngrade = () => import("../../SubscriptionDowngrade");
const ConfirmationPage = () => import("../../SubscriptionNew/ConfirmationPage");
const Onboarding = () => import("../../Onboarding");
// Programs
const Programs = () => import("../../Programs");
const ProgramCategoryLanding = () => import("../../Programs/ProgramCategoryLanding");
const Program = () => import("../../Programs/ProgramPage");
const InviteToProgram = () => import("../../Programs/InviteToProgram");
const EnrollInProgram = () => import("../../Programs/EnrollInProgram");

// Progress
const ProgressPage = () => import("../../Progress/ProgressPage");
const AchievementsPage = () =>
  import("../../Progress/_components/Pages/AchievementsPage/AchievementsPage");
const AllAchievementsPage = () =>
  import("../../Progress/_components/Pages/AchievementsPage/AllAchievementsPage");
const AchievementsByGoalPage = () =>
  import("../../Progress/_components/Pages/AchievementsPage/AchievementsByGoalPage");
const PointsPage = () => import("../../Progress/_components/Pages/PointsPage/PointsPage");
const QuestsPage = () => import("../../Progress/_components/Pages/QuestsPage/QuestsPage");
const QuestDetails = () => import("../../Progress/_components/Pages/QuestsPage/QuestDetailsPage");

// Search
const Search = () => import("../../Search/Search");
const CourseFinderResults = () => import("../../Search/CourseFinderResults");
const SearchResultsDetails = () => import("../../Search/SearchResultsDetails");

// Webinar/Events
const WebinarListPage = () => import("../../Webinar/WebinarListPage");
const WebinarDetailsPage = () => import("../../Webinar/WebinarDetailsPage");

export const authUserPaths = [
  {
    path: ROUTES.DASHBOARD,
    component: Home,
    noShell: false,
  },
  {
    path: ROUTES.SUBSCRIPTIONS_NEW,
    component: SubscriptionNew,
    noShell: true,
  },
  {
    path: ROUTES.SUBSCRIPTIONS_UPGRADE,
    component: SubscriptionUpgrade,
    noShell: true,
  },
  {
    path: ROUTES.SUBSCRIPTIONS_DOWNGRADE,
    component: SubscriptionDowngrade,
    noShell: true,
  },
  {
    path: `${ROUTES.MEMBERSHIP}${ROUTES.CONFIRMATION}`,
    component: ConfirmationPage,
    noShell: true,
  },
  {
    path: ROUTES.ONBOARDING,
    component: Onboarding,
    noShell: true,
  },
  {
    path: `${ROUTES.PARTNER}${ROUTES.NEW}`,
    component: PartnerRegistration,
    noShell: true,
  },
  {
    path: ROUTES.CHALLENGES,
    component: Challenges,
    noShell: false,
  },
  {
    path: `${ROUTES.CHALLENGES}${ROUTES.CREATE}`,
    component: CreateChallenge,
    noShell: false,
  },
  {
    path: `${ROUTES.CHALLENGE}${ROUTES.INVITE}/:id`,
    component: InviteChallengers,
    noShell: false,
  },
  {
    path: `${ROUTES.CHALLENGE}${ROUTES.EDIT}/:id`,
    component: CreateChallenge,
    noShell: false,
  },
  {
    path: `${ROUTES.CHALLENGE}/:id`,
    component: Challenge,
    noShell: false,
  },
  {
    path: ROUTES.TEAM_CHALLENGES,
    component: TeamChallenges,
    noShell: false,
  },
  {
    path: `${ROUTES.TEAM_CHALLENGES}${ROUTES.CREATE}`,
    component: CreateTeamChallengeDetails,
    noShell: false,
  },
  {
    path: `${ROUTES.TEAM_CHALLENGES}${ROUTES.EDIT}/:id`,
    component: CreateTeamChallengeDetails,
    noShell: false,
  },
  {
    path: `${ROUTES.TEAM_CHALLENGES}${ROUTES.EDIT}/:id${ROUTES.TEAMS}`,
    component: ManageChallengeTeams,
    noShell: false,
  },
  {
    path: `${ROUTES.TEAM_CHALLENGES}${ROUTES.EDIT}/:id${ROUTES.TEAMS}/:teamID`,
    component: CreateChallengeTeams,
    noShell: false,
  },
  {
    path: `${ROUTES.TEAM_CHALLENGES}${ROUTES.EDIT}/:id${ROUTES.TEAMS}${ROUTES.CREATE}`,
    component: CreateChallengeTeams,
    noShell: false,
  },
  {
    path: `${ROUTES.TEAM_CHALLENGES}/show/:tab`,
    component: TeamChallenges,
    noShell: false,
  },
  {
    path: `${ROUTES.TEAM_CHALLENGES}/:id`,
    component: TeamChallengeDetails,
    noShell: false,
  },
  {
    path: ROUTES.CHAMPION,
    component: Champion,
    noShell: false,
  },
  {
    path: ROUTES.COMMUNITIES,
    component: Communities,
    noShell: false,
  },
  {
    path: `${ROUTES.COMMUNITY}/:id`,
    component: Community,
    noShell: false,
  },
  {
    path: `${ROUTES.COMMUNITY}/:id/:tab`,
    component: Community,
    noShell: false,
  },
  {
    path: `${ROUTES.COURSE}${ROUTES.VIDEO}/:roomId`,
    component: SetupGroupVodCourse,
    noShell: true,
  },
  {
    path: `${ROUTES.COURSE}/:id${ROUTES.VIDEO}`,
    component: CourseVideo,
    noShell: true,
  },
  {
    path: `${ROUTES.COURSE}/:id${ROUTES.LIVESTREAM}`,
    component: CourseLive,
    noShell: true,
  },
  {
    path: `${ROUTES.COURSE}${ROUTES.RECORDINGS}/:id`,
    component: LiveRecordings,
    noShell: true,
  },
  {
    path: `${ROUTES.CREATEROOM}`,
    component: CreateLiveRoom,
    noShell: true,
  },
  {
    path: `${ROUTES.COURSES}${ROUTES.PARTNERSHIPS}`,
    component: PrivateCourses,
    noShell: false,
  },
  {
    path: `${ROUTES.COURSES}${ROUTES.PRIVATE}/:type`,
    component: PrivateCourses,
    noShell: false,
  },
  {
    path: `${ROUTES.COURSES}${ROUTES.CATEGORIES}`,
    component: CoursesCategories,
    noShell: false,
  },
  {
    path: `${ROUTES.COURSES}${ROUTES.ZONES}`,
    component: CoursesCategories,
    noShell: false,
  },
  {
    path: `${ROUTES.COURSES}${ROUTES.RECOMMENDATIONS}`,
    component: RecommendedCourses,
    noShell: false,
  },
  {
    path: `${ROUTES.COURSES}/:filter`,
    component: Courses,
    noShell: false,
  },
  {
    path: `${ROUTES.COURSES}${ROUTES.CATEGORY}`,
    component: Courses,
    noShell: false,
  },
  {
    path: `${ROUTES.COURSES}${ROUTES.ZONE}`,
    component: Courses,
    noShell: false,
  },
  {
    path: `${ROUTES.COURSES}${ROUTES.CATEGORY}/:id`,
    component: Courses,
    noShell: false,
  },
  {
    path: `${ROUTES.COURSES}${ROUTES.ZONE}/:id`,
    component: Courses,
    noShell: false,
  },
  {
    path: `${ROUTES.COURSE}/:id`,
    component: Course,
    noShell: false,
  },
  {
    path: `${ROUTES.COURSE}/:id${ROUTES.LIVE}/:eventId`,
    component: Course,
    noShell: false,
  },
  {
    path: `${ROUTES.COURSE}/:id${ROUTES.OPEN_DOOR}/:eventId`,
    component: Course,
    noShell: false,
  },
  {
    path: `${ROUTES.COURSE}/:id${ROUTES.LEAVE_REVIEW}`,
    component: LeaveCourseReview,
    noShell: false,
  },
  {
    path: `${ROUTES.COURSE}/:id${ROUTES.POST_ASSESSMENT}/:historyId`,
    component: PostAssessmentForm,
    noShell: false,
  },
  {
    path: `${ROUTES.COURSE}/:id${ROUTES.SCHEDULE}/:eventId`,
    component: ScheduleCourse,
    noShell: false,
  },
  {
    path: `${ROUTES.COURSE}/:id${ROUTES.SCHEDULE}`,
    component: ScheduleCourse,
    noShell: false,
  },
  {
    path: `${ROUTES.COURSE}/:id/:tab`,
    component: Course,
    noShell: false,
  },
  {
    path: ROUTES.COURSES,
    component: CoursesCategories,
    noShell: false,
  },

  {
    path: ROUTES.DESIGN,
    component: DesignGuide,
    noShell: false,
  },
  {
    path: `${ROUTES.DESIGN}/:type`,
    component: Showcase,
    noShell: false,
  },
  {
    path: ROUTES.INSTRUCTORS,
    component: Instructors,
    noShell: false,
  },
  {
    path: `${ROUTES.INSTRUCTOR}/:id`,
    component: Instructor,
    noShell: false,
  },
  {
    path: `${ROUTES.INSTRUCTOR}/:id/:tab`,
    component: Instructor,
    noShell: false,
  },
  {
    path: ROUTES.INTERESTS,
    component: Interests,
    noShell: false,
  },
  {
    path: ROUTES.PARTNERS,
    component: Partners,
    noShell: false,
  },
  {
    path: `${ROUTES.PARTNER}/:id`,
    component: Partner,
    noShell: false,
  },
  {
    path: `${ROUTES.PARTNER}/:id/:tab`,
    component: Partner,
    noShell: false,
  },
  {
    path: ROUTES.FAVORITES,
    component: Favorites,
    noShell: false,
  },
  {
    path: `${ROUTES.PLAYLIST}/:id${ROUTES.EDIT}${ROUTES.COURSES}`,
    component: EditPlaylistCourses,
    noShell: false,
  },
  {
    path: `${ROUTES.PLAYLIST}/:id${ROUTES.INVITE}`,
    component: InviteUserRole,
    noShell: false,
  },
  {
    path: `${ROUTES.PLAYLIST}/:id${ROUTES.EDIT}`,
    component: AddPlaylistForm,
    noShell: false,
  },
  {
    path: `${ROUTES.PLAYLIST}${ROUTES.NEW}`,
    component: AddPlaylistForm,
    noShell: false,
  },
  {
    path: `${ROUTES.PLAYLIST}/all`,
    component: BrowsePlaylists,
    noShell: false,
  },

  {
    path: `${ROUTES.PLAYLIST}/:id`,
    component: Playlist,
    noShell: false,
  },
  {
    path: `${ROUTES.PLAYLIST}`,
    component: MyPlaylist,
    noShell: false,
  },
  {
    path: ROUTES.PROFILE,
    component: Profile,
    noShell: false,
  },
  {
    path: `${ROUTES.PROFILE}${ROUTES.EDIT}`,
    component: EditProfile,
    noShell: false,
  },
  {
    path: `${ROUTES.PROFILE}${ROUTES.EDIT}${ROUTES.DETAIL}`,
    component: EditDynamicFields,
    noShell: false,
  },
  {
    path: `${ROUTES.PROFILE}${ROUTES.HOMEWORK}`,
    component: HomeworkList,
    noShell: false,
  },
  {
    path: `${ROUTES.PLN}`,
    component: PlnLanding,
    noShell: false,
  },
  {
    path: `${ROUTES.PROFILE}${ROUTES.MEMBERSHIP}`,
    component: ProfileMembershipPlan,
    noShell: false,
  },
  {
    path: `${ROUTES.PROFILE}${ROUTES.MEMBERSHIP}${ROUTES.EDIT}`,
    component: ProfileMembershipPlan,
    noShell: false,
  },
  // TODO REMOVE AFTER NEW PAYMENT REPLACES
  {
    path: `${ROUTES.PROFILE}${ROUTES.MEMBERSHIP}${ROUTES.CONFIRMATION}`,
    component: MembershipConfirmationPage,
    noShell: false,
  },
  {
    path: `${ROUTES.PROFILE}${ROUTES.WELLNESS_LEVEL}`,
    component: WellnessLevel,
    noShell: false,
  },
  {
    path: ROUTES.CONDITIONS,
    component: Conditions,
    noShell: false,
  },
  {
    path: ROUTES.WELLNESS_HABITS,
    component: WellnessHabits,
    noShell: false,
  },
  {
    path: ROUTES.WELLNESS_GOALS,
    component: WellnessGoals,
    noShell: false,
  },
  {
    path: ROUTES.SETTINGS,
    component: Settings,
    noShell: false,
  },
  {
    path: ROUTES.SUB_ACCOUNTS,
    component: SubAccounts,
    noShell: false,
  },
  {
    path: ROUTES.CALENDAR,
    component: Calendar,
    noShell: false,
  },
  {
    path: ROUTES.SCHEDULE,
    component: Schedule,
    noShell: false,
  },
  {
    path: ROUTES.HISTORY,
    component: History,
    noShell: false,
  },
  {
    path: `${ROUTES.PROFILE}${ROUTES.CONTACTS}${ROUTES.MY_CONTACTS}`,
    component: MyContacts,
    noShell: false,
  },
  {
    path: `${ROUTES.PROFILE}${ROUTES.CONTACTS}`,
    component: Contacts,
    noShell: false,
  },
  {
    path: ROUTES.CONTACTS,
    component: Contacts,
    noShell: false,
  },
  {
    path: `${ROUTES.PROFILE}${ROUTES.CONTACTS}${ROUTES.ADD_CONTACTS}`,
    component: AddContact,
    noShell: false,
  },
  {
    path: ROUTES.PROGRAMS,
    component: Programs,
    noShell: false,
  },
  {
    path: `${ROUTES.PROGRAMS}${ROUTES.CATEGORY}/:type`,
    component: ProgramCategoryLanding,
    noShell: false,
  },
  {
    path: `${ROUTES.PROGRAM}${ROUTES.ENROLL}/:id`,
    component: EnrollInProgram,
    noShell: false,
  },
  {
    path: `${ROUTES.PROGRAM}${ROUTES.INVITE}/:id`,
    component: InviteToProgram,
    noShell: false,
  },
  {
    path: `${ROUTES.PROGRAM}/:id`,
    component: Program,
    noShell: false,
  },
  {
    path: `${ROUTES.PROGRAM}/:id/:tab`,
    component: Program,
    noShell: false,
  },
  {
    path: ROUTES.PROGRESS,
    component: ProgressPage,
    noShell: false,
  },
  {
    path: ROUTES.POINTS_HISTORY,
    component: PointsPage,
    noShell: false,
  },
  {
    path: ROUTES.QUESTS,
    component: QuestsPage,
    noShell: false,
  },
  {
    path: `${ROUTES.QUESTS}/:goal_id`,
    component: QuestDetails,
    noShell: false,
  },
  {
    path: ROUTES.ACHIEVEMENTS,
    component: AchievementsPage,
    noShell: false,
  },
  {
    path: `${ROUTES.ACHIEVEMENTS_ALL}`,
    component: AllAchievementsPage,
    noShell: false,
  },
  {
    path: `${ROUTES.ACHIEVEMENTS}/:goal_id`,
    component: AchievementsByGoalPage,
    noShell: false,
  },
  {
    path: ROUTES.COURSE_FINDER,
    component: CourseFinderResults,
    noShell: false,
  },
  {
    path: `${ROUTES.SEARCH}${ROUTES.RESULTS}/:q/:type`,
    component: SearchResultsDetails,
    noShell: false,
  },
  {
    path: `${ROUTES.SEARCH}${ROUTES.RESULTS}${ROUTES.COURSE_FINDER}`,
    component: SearchResultsDetails,
    noShell: false,
  },
  {
    path: `${ROUTES.SEARCH}/:q`,
    component: Search,
    noShell: true,
  },
  {
    path: `${ROUTES.SEARCH}`,
    component: Search,
    noShell: true,
  },
  {
    path: `${ROUTES.EVENTS}`,
    component: WebinarListPage,
    noShell: false,
  },
  {
    path: `${ROUTES.EVENT}/:id`,
    component: WebinarDetailsPage,
    noShell: false,
  },
  {
    path: ROUTES.ACCESS_DENIED,
    component: AccessDenied,
    noShell: false,
  },
];

const reactRouterMapping = authUserPaths.map(({ component, noShell, path }) => ({
  path,
  errorElement: <ErrorBoundary />,
  lazy: async () => {
    const loadedComponent = await component();
    return {
      Component: renderAuthorizedRoute({
        children: <loadedComponent.default />,
        noShell,
      }),
    };
  },
}));

export default reactRouterMapping;

export const authUserPathsNoProviders = authUserPaths.map(({ component, path }) => ({
  path,
  async lazy() {
    const Component = await component();
    return { Component: Component.default };
  },
}));
